import React from 'react'
import Layout from "../layouts/de"
import Block from "../components/Block"

const InfoPage = (props) => (
  <Layout 
    location={props.location} 
    title="Impressum - Online Kreativagentur"
    description="Hier findest du alle wichtigen Daten, du kannst mir deine Anfrage auch an meine E-Mail-Adresse mail@lopezdesign.de schicken."
    >
    <Block color="white"
      left={
        <div className="grid__item space--bottom">
          <h1 className="shine">Impressum</h1>
          <p className="space--none">lopezdesign - Kleingewerbe</p>
          <p>Cristian López</p>

          <p className="space--none">97318 Kitzingen</p>
          <p>Deutschland</p>

          <p className="space--none">E-Mail: mail@lopezdesign.de</p>
          <p>Web: lopezdesign.de</p>

          <h5>Umsatzsteuer-ID:</h5>
          <p>Gemäß § 19 Abs. 1 UStG wird keine Umsatzsteuer ausgewiesen.</p>
        </div>
      }
    />
  </Layout>
)

export default InfoPage

